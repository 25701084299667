/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class ClientPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match('viewClients');
    this.edit = permissionChecker.match('editClient');
    this.destroy = permissionChecker.match('deleteClient');
    this.changeStatus = permissionChecker.match('changeClientStatus')
    this.print = permissionChecker.match('printClientsTable');
    this.export = permissionChecker.match('exportClientsTable');
    this.sendNotification = permissionChecker.match('sendNotification');
  }
}
